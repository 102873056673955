import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import { CLEAR_CLIENT, RESET_CLIENT_PORTAL } from 'store/clientManagement/constant';
import { RESET_LIFE_INSURANCE } from 'store/BonsaiExchange/constant';
import { RESET_LONG_TERM_CARE } from 'store/LongtermCare/constant';
import { useDispatch } from 'react-redux';
import { RESET_FINANCIAL_PLAN } from 'store/financialTool/constant';

const BonsaiChildren = [
  'Term life insurance',
  'Permanent life insurance',
  'Variable universal life',
  'Annuities',
  'Life insurance',
  'Variable annuity',
  'Long term care',
  'Medicare supplement',
  'Disability insurance',
  'Travel assistance'
];

const GoBackButton = ({ title, portal, client, isBonsaiExchange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoBack = () => {
    if (portal) {
      dispatch({
        type: RESET_LIFE_INSURANCE,
        payload: {}
      });
      dispatch({
        type: RESET_LONG_TERM_CARE,
        payload: {}
      });
      dispatch({
        type: RESET_CLIENT_PORTAL,
        payload: {}
      });
      dispatch({
        type: RESET_FINANCIAL_PLAN,
        payload: ''
      });
      navigate('/bonsai_exchange');
    } else if (client) {
      dispatch({
        type: CLEAR_CLIENT,
        payload: {}
      });
      navigate(-1); // Go back to the previous page
    } else if (BonsaiChildren.includes(title)) {
      navigate('/bonsai_exchange');
    } else {
      navigate(-1); // Go back to the previous page
    }
  };

  if (
    title === 'Faq' ||
    title === 'Go Back' ||
    (isBonsaiExchange && BonsaiChildren.includes(title)) ||
    title === 'Edit client' ||
    title === 'User account' ||
    title === 'Client details' ||
    portal
  ) {
    return (
      <ButtonBase disableRipple>
        <ArrowBack sx={{ fill: '#0B8A6E', mr: 1, ml: -1 }} onClick={handleGoBack} />
      </ButtonBase>
    );
  }
  return;
};

export default GoBackButton;
