// assets
import config from '../config';
import { ReactComponent as BonsaiExchange } from 'assets/images/icons/BonsaiExchange.svg';
import { ReactComponent as FinancialTool } from 'assets/images/icons/FinancialTool.svg';
import { ReactComponent as FormSubmission } from 'assets/images/icons/FormSubmission.svg';
import { ReactComponent as Settings } from 'assets/images/icons/Settings.svg';
import { ReactComponent as ClientManagement } from 'assets/images/icons/ClientManagement.svg';
import { ReactComponent as MyAccount } from 'assets/images/icons/MyAccount.svg';
import { ReactComponent as Support } from 'assets/images/support.svg';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArticleIcon from '@mui/icons-material/Article';
import YouTubeIcon from '@mui/icons-material/YouTube';

const {
  ROLES: { CLIENT, ADVISOR, USER, ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, LTC_MANAGER, SUPER_ADMIN, XLS_MANAGER },
  SUBSCRIPTIONS: { STANDARD, PREMIUM },
  EXTERNAL_URLS: { TERM_LIFE_INSURANCE, ANNUITIES, CONTACT_SUPPORT, TRAVEL_ASSISTANCE, DISABILITY_INSURANCE }
} = config;

const roles = JSON.parse(localStorage.getItem('Roles')) || [];
const financialEnabled = localStorage.getItem('Status') == 'true';
const subscription = localStorage.getItem('Subscription');

export const Pages = {
  BonsaiExchange: {
    id: 'bonsai_exchange',
    title: 'Bonsai Exchange',
    type: 'collapse',
    url: '/bonsai_exchange',
    target: true,
    icon: BonsaiExchange,
    clientPortal: true,
    access: [ADVISOR, USER, ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, LTC_MANAGER, XLS_MANAGER, SUPER_ADMIN],
    children: [
      {
        id: 'annuities',
        title: 'Annuities',
        type: 'item',
        url: ANNUITIES,
        target: true
      },
      {
        id: 'disability_insurance',
        title: 'Disability Insurance',
        type: 'item',
        url: DISABILITY_INSURANCE,
        target: true
      },
      {
        id: 'life_insurance',
        title: 'Life Insurance',
        type: 'collapse',
        url: '/bonsai_exchange/life_insurance',
        target: true,
        childOpen: false,
        breadcrumbs: false,
        children: [
          {
            id: 'permanent_life_insurance',
            title: 'Permanent',
            type: 'item',
            url: '/bonsai_exchange/life_insurance/permanent_life_insurance',
            breadcrumbs: false
          },
          {
            id: 'term_life_insurance',
            title: 'Term',
            type: 'item',
            url: TERM_LIFE_INSURANCE,
            target: true
          },
          {
            id: 'variable_universal_life',
            title: 'Variable Universal Life',
            type: 'item',
            url: '/bonsai_exchange/life_insurance/variable_universal_life'
          }
        ]
      },
      {
        id: 'long_term_care',
        title: 'Long Term Care Insurance',
        type: 'item',
        url: '/bonsai_exchange/long_term_care',
        breadcrumbs: false
      },

      {
        id: 'medicare_supplement',
        title: 'Medicare Supplement',
        type: 'item',
        url: '/bonsai_exchange/medicare_supplement',
        breadcrumbs: false
      },
      {
        id: 'private_placement',
        title: 'Private Placement',
        type: 'collapse',
        url: '/bonsai_exchange/private_placement',
        target: true,
        childOpen: false,
        breadcrumbs: false,
        children: [
          {
            id: 'private_placement_life_insurance',
            title: 'Life Insurance',
            type: 'item',
            url: '/bonsai_exchange/private_placement/life_insurance',
            breadcrumbs: false
          },
          {
            id: 'private_placement_variable_annuity',
            title: 'Variable Annuity',
            type: 'item',
            url: '/bonsai_exchange/private_placement/variable_annuity',
            breadcrumbs: false
          }
        ]
      },
      {
        id: 'travel_assistance',
        title: 'Travel Assistance',
        type: 'item',
        url: TRAVEL_ASSISTANCE,
        target: true
      }
    ]
  },
  FormSubmission: {
    id: 'form_submission',
    title: 'Form Submission',
    type: 'collapse',
    url: '/form_submission',
    target: true,
    childOpen: true,
    access: [ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, LTC_MANAGER, XLS_MANAGER, SUPER_ADMIN],
    icon: FormSubmission,
    children: [
      {
        id: 'admin_life_insurance',
        title: 'Life Insurance',
        type: 'item',
        access: [ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, SUPER_ADMIN],
        url: '/form_submission/life_insurance',
        breadcrumbs: false
      },
      {
        id: 'admin_long_term_care',
        title: 'Long Term Care',
        type: 'item',
        access: [ADMIN, ENTERPRISE_ADMIN, LTC_MANAGER, SUPER_ADMIN],
        url: '/form_submission/long_term_care',
        breadcrumbs: false
      },
      {
        id: 'axcelus_submissions',
        title: 'Axcelus Submissions',
        type: 'item',
        access: [ADMIN, ENTERPRISE_ADMIN, XLS_MANAGER, SUPER_ADMIN],
        url: '/form_submission/axcelus_submissions',
        breadcrumbs: false
      }
    ]
  },
  UserManagement: {
    id: 'user_management',
    title: 'User Management',
    type: 'item',
    url: '/user_management',
    icon: MyAccount,
    access: [ADMIN, SUPER_ADMIN, ENTERPRISE_ADMIN],
    breadcrumbs: false
  },
  FinancialPlanning: {
    id: 'financial_planning',
    title: 'MyBonsai',
    type: 'item',
    url: '/financial_planning',
    icon: FinancialTool,
    access: [
      ...(financialEnabled ? [CLIENT] : []),
      ...(subscription !== STANDARD ? (subscription === PREMIUM ? [ADVISOR, ADMIN] : [ADVISOR]) : [])
    ],
    breadcrumbs: false
  },
  ClientManagement: {
    id: 'client_management',
    title: 'Client Management',
    type: 'item',
    url: '/client_management',
    icon: ClientManagement,
    access: subscription !== STANDARD ? (subscription === PREMIUM ? [ADVISOR, ADMIN] : [ADVISOR]) : [],
    breadcrumbs: false
  },
  Tenants: {
    id: 'tenants',
    title: 'Tenants',
    type: 'item',
    url: '/tenants',
    icon: PeopleAltIcon,
    access: [ADMIN, SUPER_ADMIN, ENTERPRISE_ADMIN],
    breadcrumbs: false,
    iconProps: {
      sx: {
        width: 30,
        height: 30,
        fillOpacity: '0.6'
      }
    }
  },
  MarketingMaterial: {
    id: 'marketing_material',
    title: 'Marketing Material',
    type: 'item',
    url: '/marketing_material',
    icon: ArticleIcon,
    access: [SUPER_ADMIN],
    iconProps: {
      sx: {
        width: 30,
        height: 30,
        fill: '#666666'
      }
    },
    breadcrumbs: false
  },
  YoutubeMedia: {
    id: 'youtube_media',
    title: 'Youtube Media',
    type: 'item',
    url: '/youtube_media',
    icon: YouTubeIcon,
    access: [SUPER_ADMIN],
    iconProps: {
      sx: {
        width: 30,
        height: 30,
        fill: '#666666'
      }
    },
    breadcrumbs: false
  },
  MyAccount: {
    id: 'my_account',
    title: 'My Account',
    type: 'item',
    url: '/my_account',
    icon: MyAccount,
    access: [CLIENT, ADVISOR, USER, ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, LTC_MANAGER, XLS_MANAGER, SUPER_ADMIN],
    breadcrumbs: false
  },
  Settings: {
    id: 'Settings',
    title: 'Settings',
    type: 'item',
    url: '/Settings',
    icon: Settings,
    access: [],
    breadcrumbs: false,
    disabled: true
  },
  Support: {
    id: 'Support',
    title: 'Support',
    type: 'item',
    icon: Support,
    access: [CLIENT, ADVISOR, USER, ADMIN, ENTERPRISE_ADMIN, LI_MANAGER, LTC_MANAGER, XLS_MANAGER, SUPER_ADMIN],
    url: CONTACT_SUPPORT,
    target: true
  }
};

// Helper function to check if user has required access
const hasAccess = (allowedRoles) => {
  if (!allowedRoles || !allowedRoles.length) return false;
  return roles.some((userRole) => allowedRoles.includes(userRole));
};

// Filter available pages based on user's roles and access permissions
const getAvailablePages = () => {
  const availablePages = [];
  Object.values(Pages).forEach((page) => {
    if (hasAccess(page.access)) {
      availablePages.push(
        !page.children ? page : { ...page, children: page.children.filter((child) => !child.access || hasAccess(child.access)) }
      );
    }
  });

  return availablePages;
};

const pages = {
  id: 'pages',
  type: 'group',
  children: getAvailablePages()
};

export default pages;
