import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Box, MenuItem } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FieldTypes, RHFPercentageField } from 'ui-component/RHF';

export default function ListMap({ ...props }) {
  const { control, watch, setValue } = useFormContext();
  const { append, remove } = useFieldArray({
    control,
    name: props.name
  });
  const [error, setError] = React.useState(false);
  const [text, setText] = React.useState();
  let values = watch(props.name) ?? [];
  values = Array.isArray(values) ? values : [];

  const valuesMap = values?.reduce((acc, value, idx) => {
    acc[value?.asset_class_id] = idx;
    return acc;
  }, {});

  const handleSelect = (option) => {
    if (valuesMap[`${option?.value}`] === undefined) {
      if (!Array.isArray(values)) {
        setValue(props.name, []);
      }
      append({ asset_class_id: option?.value, percentage: 0 });
    } else {
      remove(valuesMap[`${option?.value}`]);
    }
  };

  const handlePercentageChange = (idx, value) => {
    const newValue = parseFloat(value) || 0;
    const totalPercentage = values.reduce((sum, item) => sum + (item.percentage || 0), 0) - (values[idx]?.percentage || 0) + newValue;

    if (totalPercentage > 100) {
      setText(`The sum of percentages cannot be greater than 100%`);
      setError(true);
      setValue(`${props.name}.${idx}.percentage`, newValue);
    } else {
      setError(false);
      setText('');
      setValue(`${props.name}.${idx}.percentage`, newValue);
    }
  };

  return (
    <Box>
      <TextField
        id={props.id}
        label={props.label}
        name={props.name}
        value={values?.filter((value) => value !== undefined)} // Set value to the array of selected values
        select
        onClick={(e) => e.stopPropagation()} // Prevent default dropdown behavior
        SelectProps={{
          multiple: true,
          renderValue: () =>
            props.options
              .flatMap((option) =>
                valuesMap[`${option?.value}`] !== undefined
                  ? `${option?.label} (${values[valuesMap[`${option?.value}`]]?.percentage || 0}%)`
                  : []
              )
              .join(', ')
        }}
        error={props.error || error}
        helperText={props.helperText || text}
        fullWidth
        disabled={props.disabled}
      >
        {/* {error && `${text}`} */}
        {props.options?.map((option) => (
          <MenuItem key={option?.value} onClick={() => handleSelect(option)} value={option?.value}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Box>
                {option?.label}
                {/* {valuesMap[`${option?.value}`] !== undefined && error && <Box sx={{ color: 'error.main', fontSize: '14px' }}>{text}</Box>} */}
              </Box>
              {valuesMap[`${option?.value}`] !== undefined && (
                <RHFPercentageField
                  name={`${props.name}.${valuesMap[`${option?.value}`]}`}
                  onChange={(e) => handlePercentageChange(valuesMap[`${option?.value}`], e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  label={`%`}
                  // invalid={error}
                  value={values[valuesMap[`${option?.value}`]]?.percentage || 0}
                  type={FieldTypes.Percentage}
                  sx={{ width: '100px', marginLeft: '8px' }}
                />
              )}
            </Box>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
