import dayjs from 'dayjs';
import { DateFormats, FieldTypes } from 'ui-component/RHF';
import * as Yup from 'yup';

export const yupDate = (format = DateFormats[FieldTypes.Date], message) => {
  return Yup.string()
    .nullable()
    .test('date', message ?? 'Invalid date entered', (value) => {
      if (value) {
        if (value.includes('-')) {
          return dayjs(value).isValid();
        }
        return dayjs(value, format).isValid();
      }
      return true;
    });
};

export const maxDateCompare = (yup, endDate, message, format = DateFormats[FieldTypes.Date]) => {
  if (endDate) {
    return yup.test('maxDate', message, (value) => {
      if (value) {
        let dateValue = dayjs(value, format);
        let endDateValue = dayjs(endDate, format);

        if (dateValue?.isValid()) {
          return dateValue.isSame(endDateValue, 'day') || dateValue.isBefore(endDateValue, 'day');
        }
        return false;
      }
      return true;
    });
  }
};
