import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAmortizationTableData } from 'store/financialTool/action';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, Typography } from '@mui/material';

const AmortizationTable = ({ financialPlanId }) => {
  const dispatch = useDispatch();
  const amortizationTableData = useSelector((state) => (financialPlanId ? state?.financialPlan?.amortizationTableData : []));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      financialPlanId && (await dispatch(getAmortizationTableData(financialPlanId)));
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialPlanId, dispatch]);

  return (
    <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : amortizationTableData?.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {['Year', 'Interest Payment', 'Principle Payment', 'New Balance'].map((header) => (
                <TableCell key={header} sx={{ background: '#418A6E', color: '#fff' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {amortizationTableData?.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ whiteSpace: 'normal' }}>{item?.year}</TableCell>
                <TableCell sx={{ whiteSpace: 'normal' }}>
                  {item?.insurance_payment?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </TableCell>
                <TableCell>{item?.principle_payment?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                <TableCell>{item?.new_balance?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default AmortizationTable;
